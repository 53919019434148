:root {
  color-scheme: dark;
}

body {
  background-color: rgb(32, 32, 32);
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
