.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}

.align-text {
  justify-self: right;
  align-self: center;
  font-size: 4vmin;
}

.logo-properties {
  justify-self: center;
  align-self: center;
  width: 100%;
  max-width: 60px;
  height: auto;
  animation: spin linear infinite 3000ms;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.observer-visible {
  opacity: 1;
  animation: fade-in 1s forwards;
}

.card-container {
  opacity: 0;
}
