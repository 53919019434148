.contact-parent-container {
  display: flex;
  justify-content: center;
}

.contact-container {
  max-width: 500px;
  display: grid;
  grid-template-columns: minmax(200px, 600px);
  border-radius: 20px;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-image: linear-gradient(to right bottom, grey, #30859d93);
}

.icon {
  font-size: 2.8vmin;
  margin-right: 0.5em;
}

.contact-container img {
  width: 130px;
  height: auto;
  border-radius: 50%;
  justify-self: center;
  margin: 0.5em;
  border: 4px solid rgb(72, 132, 164);
}

.contact-container a,
p {
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  color: white;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.resume-btn {
  padding: 1rem 2rem;
  border-radius: 10px;
  background-color: inherit;
  color: white;
  border: 3px solid rgb(72, 132, 164);
  font-size: 2.8vmin;
  margin-bottom: 3em;
  margin-top: 0.5em;
}

@media only screen and (max-width: 700px) {
  .contact-container a,
  p {
    font-size: 16px;
  }
}
