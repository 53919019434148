.nav-bar-container {
  height: 4.6rem;
  margin: 0.3rem 0.3rem;
  overflow: hidden;
  background-color: rgb(81, 81, 81);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8em;
  border-radius: 5px;
  box-shadow: 5px 5px 10px #000000;
  transition: transform 150ms ease-in-out;
  position: sticky;
  top: 0;
  animation-name: navbarEffect;
  animation-timing-function: ease;
  animation-duration: 0.8s;
  z-index: 10;
}

@keyframes navbarEffect {
  0% {
    transform: translateY(-80px);
  }

  100% {
    transform: translateY(0px);
  }
}

div.nav-bar--hidden {
  transform: translateY(-110%);
}

.nav-bar-icons-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.nav-bar-icons-container .footer-icon {
  font-size: 1.4rem;
  color: white;
}

@media only screen and (max-width: 700px) {
  .nav-bar-container {
    gap: 1rem;
  }
}
