.slider__container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  margin-bottom: 1.2rem;

  .slider__btn {
    position: absolute;
    top: 61%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    cursor: pointer;
    &:hover,
    :active {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.slider__btn-prev {
  left: -5px;
  z-index: 10;
}

.slider__btn-next {
  right: -5px;
  z-index: 10;
}

//slide effect
.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  &.active {
    opacity: 1;
    z-index: 1;
  }
}

.slider__header {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 100;
  font-family: inherit;
  opacity: 0;
  transform: translateY(100px);
  font-size: 2.5rem;
  transition: opacity, transform 1s ease;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}

.slider__description {
  opacity: 0;
  font-family: inherit;
  font-size: 1.1rem;
  font-weight: 100;
  transform: translateY(100px);
  transition: opacity, transform 1.3s ease;
  line-height: 1.4rem;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}

.headers__container {
  width: 100%;
  max-width: 375px;
  text-align: center;

  .learn-more__btn {
    z-index: 10;
    opacity: 0;
    color: white;
    padding: 0.8rem 1.6rem;
    border: 2px solid white;
    font-family: inherit;
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 800;
    letter-spacing: 2px;
    background-color: rgba(0, 0, 0, 0);
    transition: opacity, transform 1.5s ease;
    transform: translateY(120px);
    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
